.NewsLetter-container {
    padding: 14vh 0vh;
}


.NewsLetter-container .form-container {}

.NewsLetter-container .genral-btn {
    padding: 12px 46px 12px 46px;
    color: #FFFFFF;
    font-size: 12px;
    border-radius: 50px;
    text-transform: uppercase;
    background-color: #2A2522;
}

.NewsLetter-container .form-container .inp-container {
    background: transparent;
    /* border: 1px solid #70707033; */
    padding-left: 5px;
    border-radius: 32px;
    width: 50%;
    margin: auto;
    margin-top: 40px;
    border: none;
    overflow: hidden;
    background: #EFEFEF;
}

.NewsLetter-container .form-container .inp-container input {
    width: 100%;
    border: none;
    padding: 10px;
    padding-left: 15px;
    color: #7070708e;
    border-radius: 5px;
    background: transparent;

}

.NewsLetter-container .form-container .inp-container input::placeholder {
    color: #707070a8;
    padding: 0vh 2vh;

}

.NewsLetter-container .form-container .inp-container input:focus {
    outline: none;
}

.NewsLetter-container .form-container .inp-container .genral-btn {

    /* width: 200px;
    padding-top: 12px;
    padding-bottom: 12px; */
    padding: 12px 46px 12px 46px;
    color: #FFFFFF;
    font-size: 12px;
    background-color: #2A2522;
    

}

.NewsLetter-container .form-container .inp-container-resp {
    background: transparent;
    padding-left: 5px;
    border: none;
    width: 80%;
    margin: auto;
    margin-top: 40px;
    overflow: hidden;
}

.NewsLetter-container .form-container .inp-container-resp input {
    width: 100%;
    border: 1px solid white;
    padding: 10px;
    padding-left: 15px;
    border-radius: 32px;
    color: white;
    background: transparent;
}

.NewsLetter-container .form-container .inp-container-resp input::placeholder {
    color: white;
    
}

.NewsLetter-container .form-container .inp-container-resp input:focus {
    outline: none;
  
}

.NewsLetter-container .h1_main {
    font-size: 40px;
    font-weight: 700;
    color: #1F2F54;
    margin-bottom: 12px;
}

.NewsLetter-container .body-paragraph {
    
    margin: 0 auto;
    text-align: center;
    color: #717C93;
  
}

@media(max-width:768px) {
    .NewsLetter-container .form-container .inp-container input {
        width: 53%;

    }

    .NewsLetter-container .genral-btn {
        width: 50%;
        padding: 12px 10px;
        color: white;
    }

    .NewsLetter-container .form-container .inp-container {
        background: #EFEFEF;
        border: none;
        padding-left: 5px;
        border-radius: 32px;
        width: 100%;
        margin: auto;
        margin-top: 40px;
        overflow: hidden;
        
    }

    .NewsLetter-container .body-paragraph {
        width: 100%;
        margin: 0 auto;
        text-align: center;
        color: #717C93;
    }

    
    
    }