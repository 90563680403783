.important-section {
    padding: 0px 0;
    background-image: url("../../../public/images/creatives/contact-bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.contact-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 20px;
    background-size: cover;
    background-position: center;
    height: 100vh;
    color: white;
    flex-wrap: wrap;
}

.contact-container input,
.contact-container textarea {
    border: none;
    border-bottom: 0.5px solid #ffffff75;
    border-radius: 0%;
    resize: none;
    background-color: transparent;
    height: 80px;
}

.contact-container input::placeholder,
.contact-container textarea::placeholder {
    color: #FFFFFF;
    font-size: 12px;
}

.contact-container input:focus,
.contact-container textarea:focus {
    background-color: transparent;
    color: white;
    border-color: rgba(128, 128, 128, 0.26);
    outline: rgba(128, 128, 128, 0.26);

}

.contact-info {
    max-width: 40%;
    flex: 1;
}

.contact-container .para_main {
    color: #EAEBEB;
    padding: 25px 0px;
    padding-top: 0px;
    margin-top: 0px;
    font-weight: 200;
}

.contact-details {
    display: flex;
    padding: 1vh 0vh;
}

.contact-details p {
    /* display: flex;
    align-items: center;
    margin-top: 20px;
    font-family: 'manrope', sans-serif; */
}

.contact-container .social-icons {
    display: flex;
    gap: 10px;
    margin-top: 20px;
}

.contact-form {
    color: white;
    border-radius: 21px;
    max-width: 45%;
    flex: 1;
}

.contact-form .h1_main {
    font-size: 2.5rem;
    margin-bottom: 10px;
    font-weight: 600;
    color: #FFFFFF;
}

.contact-form .form-group {
    margin-bottom: 15px;
}

.contact-form .connect-button {
    background-color: transparent;
    color: #FFFFFF;
    border: none;
    padding: 16px 38px;
    border-radius: 26px;
    font-size: 12px;
    cursor: pointer;
    border: 1px solid white;
    font: normal normal normal 16px/13px Novar;
}

.contact-form .submit-button:hover {
    background-color: rgba(66, 66, 66, 0.267);
}

.contact-info .h1_main {
    font-size: 38px;
    color: #FFFFFF;
}

.contact-info .span-text {
    color: #8EC63F;
}

.contact-info .para_main {
    color: #FFFFFF;
}

.contact-container .informational-para {
    font-size: 14px;
}

.contact-container .contact-content {
    display: flex;
}

@media (max-width: 1200px) {
    .contact-container {
        padding: 30px;
        height: 100vh;
    }

    .contact-info h1 {
        font-size: 2.5rem;
    }

    .contact-form .h1_main {
        font-size: 1.8rem;
    }
}

@media (max-width: 992px) {
    .contact-info {
        max-width: 45%;
    }

    .contact-form {
        max-width: 45%;
    }

    .contact-info h1 {
        font-size: 2.2rem;
    }

    .contact-form .h1_main {
        font-size: 1.6rem;
    }

    .contact-form .contact-details {
        align-items: center;
        justify-content: center;
    }
}

@media (max-width: 768px) {
    .important-section {
        background-size: cover;
        padding: 50px 16px;
    }

    .contact-container {
        
        height: auto;
        padding: 0px;
    }

    .contact-details {
        /* flex-direction: column;
        align-items: center;
        justify-content: center; */
        gap: 0px;
        margin-bottom: 12px;
    }

    .contact-details img {
        max-width: 24px;
        height: auto;
    }

    .contact-details p {
        font-size: 0.9rem;
        margin: 0;
    }

    .contact-info {
        max-width: 100%;
        flex: none;
        margin-bottom: 20px;
    }

    .contact-form {
        max-width: 100%;
    }

    .contact-info h1 {
        font-size: 2rem;
    }

    .contact-form .h1_main {
        font-size: 1.5rem;
    }

    .contact-container .h1_main {
        text-align: center;
    }

    .contact-container .para_main {
        /* text-align: center; */
    }
}

@media (max-width: 576px) {
    .important-section {
        background-size: cover;
        padding: 50px 16px;
    }

    .contact-form {
        padding: 32px 12px;
    }

    .contact-info h1 {
        font-size: 1.9rem;
        /* text-align: center; */
    }

    .contact-form .h1_main {
        font-size: 1.4rem;
    }

    .contact-details {
        /* flex-direction: column;
        align-items: center;
        justify-content: center; */
        gap: 0px;
        margin-bottom: 12px;
    }

    .contact-details img {
        max-width: 24px;
        height: auto;
    }

    .contact-details p {
        font-size: 0.9rem;
        margin: 0;
    }

    .contact-form p {
        font-size: 0.9rem;
    }

    .contact-info {
        padding: 3vh 0vh;
    }

    .contact-info p {
        font-size: 0.9rem;
    }

    .contact-container .social-icons {
        justify-content: center;
        margin-bottom: 12px;
    }

    .contact-container .h1_main {
        text-align: center;
    }

    .contact-container .para_main {
        /* text-align: center; */
    }
}
