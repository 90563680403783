.main-cardgrid {
    padding: 2vh 2vw; /* Adjust padding for smaller screens */
}

.outside {
    padding: 6vh 2vw; /* Adjust padding for smaller screens */
}

.card-grid-box {
    padding: 0px 15px; /* Adjust padding for smaller screens */
}

.main-cardgrid .text-muted {
    color: #1972B7 !important;
}

.main-cardgrid .card-title {
    color: #070707;
    margin-bottom: 12px;
    font: normal normal normal 24px/24px Novar; /* Adjust font size for smaller screens */
}

.main-cardgrid .href {
    color: #A5CD37;
    margin-top: 5px;
}

.main-cardgrid .card-body {
    padding: 16px 0px; /* Adjust padding for smaller screens */
}

.main-cardgrid .card-img-top {
    /* border-radius: 10px; */
}

.main-cardgrid .connect-button {
    color: black;
    border: 1px solid black;
    margin: 10px auto; /* Center button on smaller screens */
    display: block;
}

.main-cardgrid .connect-button:hover {
    background-color: rgba(128, 128, 128, 0.116);
}

.main-cardgrid .para_main {
    padding: 0px 12px;
}

.main-cardgrid .text-for-box {
    font-size: 12px;
    color: #C7C7C7;
    align-items: center;
    margin: 0;
    padding: 0px 12px;
}

.main-cardgrid .para-for-card-content {
    margin: 0;
    font-weight: 400;
    color: #070707;
    font: normal normal normal 14px/28px Lato; /* Adjust font size for smaller screens */
    letter-spacing: 0px;
    margin-top: 8px;
    line-height: 1.6;
}

/* .main-cardgrid .card-img-top {
    height: 415px;
} */

@media (min-width: 576px) {
    .card-grid-box {
        padding: 0px 20px; /* Adjust padding for larger screens */
    }

    .main-cardgrid .card-title {
        font: normal normal normal 26px/24px Novar; /* Adjust font size for larger screens */
    }

    .main-cardgrid .para-for-card-content {
        font-size: 15px; /* Adjust font size for larger screens */
    }
   
}

@media (min-width: 768px) {
    .main-cardgrid .card-body {
        padding: 20px 0px; /* Adjust padding for tablets */
    }

    .main-cardgrid .card-title {
        font: normal normal normal 28px/24px Novar; /* Adjust font size for tablets */
    }

    .main-cardgrid .para-for-card-content {
        font-size: 16px; /* Adjust font size for tablets */
    }
}

@media (min-width: 992px) {
    .card-grid-box {
        padding: 0px 25px; /* Adjust padding for small laptops */
    }

    .main-cardgrid .card-title {
        font: normal normal normal 30px/24px Novar; /* Adjust font size for small laptops */
    }

    .main-cardgrid .para-for-card-content {
        font-size: 16px; /* Adjust font size for small laptops */
    }
}

@media (min-width: 1200px) {
    .card-grid-box {
        padding: 0px 30px; /* Adjust padding for larger screens */
    }

    .main-cardgrid .card-title {
        /* font: normal normal normal 32px/24px Novar;  */
    }

    .main-cardgrid .para-for-card-content {
        font-size: 17px; /* Adjust font size for larger screens */
    }
}
