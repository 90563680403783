.cards-container {
    background-color: #F9F9F9;
    padding: 5vh 0vh;
}


.cards-container .card-box {
    color: black;
    display: flex;
    padding-bottom: 30px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    gap: 10px;
    /* box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.097); */
    border-radius: 12px;
    height: 100%;
    cursor: pointer;
}

.card-list {
    margin-top: 28px;
}



.cards-container .head-h1 {
    font-size: 50px;
    color: #1F2F54;
    font-weight: 600;

}

.cards-container .swiper-slide {
    height: auto;
    padding: 4vh 0vh;
}

.cards-container .card-box .card-text-content {
    width: 93%;
    display: flex;
    flex-direction: column;
    gap: 7px;
    margin: auto;
}

.cards-container .card-box h5 {
    color: #373737;
    font-size: 1.1rem;
    font-weight: 600;
    margin-top: 10px;
}

.cards-container .card-box h2 {
    color: #1F2F54;
    font-size: 1.1rem;
    font-weight: 600;
    margin-top: 10px;
}

.cards-container .card-box p {
    color: #949494;
    font-size: .78rem;
    line-height: 1.6;
}


.cards-container .card-box a {
    text-decoration: none;
    font-size: .8rem;
    transition: .3s;

}


.cards-container .card-box a:hover {
    cursor: pointer;
    color: #E1A92A;
}

.cards-container .card-box img {
    width: 100%;
    margin-bottom: 10px;
    border-radius: 8px;
}

.cards-container .card-box .btn-icon {
    width: 12px;
    height: 12px;
    margin-bottom: 0;
    margin-left: 3px;
}

.cards-container .card-box .top-links {
    display: flex;
    justify-content: start;
    gap: 15px;
}

.cards-container.card-box .top-links img {
    width: 15px;
}

.cards-container .card-box .top-links p {
    font-size: .8rem;

}

.cards-container .blog-text-content {}

.cards-container .blog-upper-content {
    padding: 5vh 0vh;
}

.cards-container .connect-button {
    color: white;
}


.cards-container .blog-upper-content .blog-btn {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 10px;
}

.cards-container .blog-upper-content .blog-btn button {
    border-radius: 15px;
    border: none;
    padding: 10px 20px;
    color: #E1A92A;
}



.cards-container .btn-arrow-icon {
    width: 10px;
    height: 15px;
}

@media(max-width:768px) {
    .cards-container .blog-upper-content .blog-btn {
        display: flex;
        align-items: center;
        justify-content: start;
    }

    .cards-container .blog-upper-content .blog-text-content {
        text-align: center;
        margin-top: 35px;
    }

    .cards-container .head-h1 {
        font-size: 32px;
    }

    .cards-container .para_main {
        text-align: center;
        margin-bottom: 0px;
    }

    .cards-container .connect-button {
        text-align: center;
    }

    .cards-container .services-content-class {
        text-align: center;
    }
    .card-list {
        margin-top: 0px;
    }
}