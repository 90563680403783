.desktopHeader {
  /* padding: 48px 0px 15px 0px; */
  height: 0;
  width: 100%;
  background: transparent;
  position: absolute;
  z-index: 99;
}
.mobileHeader {
  display: none;
  width: 100%;
}
.nav {
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  background: transparent;
  margin-top: 30px;
  border-radius: 50px;
  padding: 0vh 1vh;

}
.logo {
  cursor: pointer;
  max-height: 160px;
  max-width: 200px;
  padding: 8px;

}
.navList {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 65px;
}
.navList a {
  text-decoration: none;
  color: white;
  font-size: 14px;
}
.link {
  font-family: "Inter", sans-serif;
  text-decoration: none;
  color: rgb(255, 255, 255);
  transition: 0.1s ease;
  font-weight: 400;
  letter-spacing: 1px;
  
}
.link:hover {
  color: #888888;
}
.activeLink {
  color: #888888;
}
.buttonCont {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  gap: 20px;
}

.buttonCont button {
  background-color: transparent;
  color:#FFFFFF; 
  border: none;
  padding: 16px 38px; 
  border-radius: 26px; 
  font-size: 14px; 
  cursor: pointer; 
  border: 1px solid white;
  font: normal normal normal 16px/13px Novar;
  

}
.button1 {
  border: 1px solid #1F2F54;
  background-color: transparent;
  color: #1F2F54;
  padding: 7px 22px 6px 22px;
  /* width: 40px; */
  transition: 0.1s ease;
}
.button1:hover {
  border: 1px solid #cb1212;
  color: #cb1212;
}
.button2 {
  border: 1px solid #cb1212;
  background-color: #EE202D;
  color: #FFFFFF;
 
}
.button2:hover {
  border-color: 1px solid #cb1212e0;
  background-color: #cb1212e0;
}
.dropdown {
  position: relative;
  display: inline-block;
}
.drop_downContent {
  position: absolute;
  border-top: 1px solid rgb(45, 45, 45);
  background-image: linear-gradient(to right, rgb(25, 25, 25) , rgb(79, 79, 79));
  width: 100%;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  z-index: 1;
  animation: fadeInFromNone 0.5s ease-out;
}
@keyframes fadeInFromNone {
  0% {
      display: none;
      opacity: 0;
  }

  1% {
      display: block;
      opacity: 0;
  }

  100% {
      display: block;
      opacity: 1;
  }
}
.listCol {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}
.listCol h3 {
  font-family: "Inter", sans-serif;
  text-align: start;
  align-self: flex-start;
  color: #c10f0f;
  font-size: 25px;
}
.listCol a {
  font-family: "Mulish";
  align-self: flex-start;
  text-align: start;
  font-weight: 500;
  font-size: 14px;
  text-decoration: none;
}
.listColLink {
  color: #ffffff;
}
.listColLinkActive {
  color: #c10f0f;
}
.listCol a:hover {
  color: #e8e8e8;
}
.box {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  background-color: #c10f0f;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  padding: 60px 50px 60px 50px;
}
.head {
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: row;
  gap: 15px;
}
.head img {
  width: 24px;
  height: 24px;
  align-self: center;
}
.head h4 {
  color: white;
  text-align: start;
  align-self: flex-start;
}
.box p {
  color: white;
  text-align: start;
  align-self: flex-start;
}
.box a {
  align-self: flex-start;
}
.box button {
  align-self: flex-start;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  font-weight: 600;
  padding: 9px 16px 9px 16px;
  letter-spacing: 0.5px;
  margin: 0;
  border-radius: 4px;
  background-color: #ffffff;
  color: #c10f0f;
  border: 1px solid #c10f0f;
  transition: 0.2s ease-in;
}
.box button:hover{
  background-color: #c10f0f;
  color: white;
  border: 1px solid white;
}


@media (max-width: 1200px) {
  .desktopHeader {
    display: none;
  }
  .mobileHeader {
    display: block;
  }
}
