.Footer-container {
    padding: 5vh 0vh;
    background: #2A2522;
}

.Footer-container .anchor {
    color: #EAEBEB;
    text-decoration: none;
    transition: .3s;
    margin-left: 12px;
    align-items: center;
    justify-content: center;
}

.Footer-container .anchor:hover {
    color: #888888;

}

.footer-last-content .para_main {
    margin-bottom: 0px;
}



.social-icons {}

.Footer-container .body-paragraph {
    color: #EAEBEB;
    width: 50%;
}

.Footer-container .footer-bottom-content {
    display: flex;
}

.Footer-Bottom-Stuff .social-icons-main {}

.Footer-Bottom-Stuff .social-icon {
    margin: 0 10px;
}

.Footer-Bottom-Stuff .social-icon:hover {
    cursor: pointer;
}

.Footer-container .footer-last-content {
    width: 990px;
}

.Footer-container .bottom-manu {
    gap: 40px;
}



@media (max-width: 768px) {
    .Footer-container .body-paragraph {
        width: 100%;
    }

    .Footer-container .footer-bottom-content {
        flex-direction: column;
    }

    .Footer-container .footer-bottom-content .anchor {
        margin-top: 12px;
        margin-left: 0px;
    }

    .Footer-container .anchor {
        margin-left: 0px;
    }

    .Footer-container .footer-last-content {
        width: 100%;
    }

    .footer-last-section {
        display: flex;
        flex-direction: column;
    }
    .Footer-container .head-h3 {
        color: rgb(146, 146, 146);
    }

    .footer-last-content .para_main {
        /* margin-bottom: 12px; */
    }

    .Footer-Bottom-Stuff .social-icons-main {
        margin-top: 12px;
    }
    .Footer-container .bottom-manu {
        gap: 20px;
    }

}