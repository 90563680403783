.location_content {
    margin-top: 2rem;
    margin-bottom: 2rem;
    text-align: center;
    padding-right: 10rem;
    padding-left: 10rem;
}

.location_content .para_main{
    /* background-color: rgb(177, 177, 177); */
 color: black;
}

