.contact-form-container {
  background-color: #F5F5F5;
  padding: 12vh 4vh;
  justify-content: space-between;
}

.contact-form-container .contact-info {
  color: white;
}

.contact-form-container .head-h2 {
  text-align: left;
font: normal normal normal 48px/87px Novar;
letter-spacing: 0px;
color: #070707;
text-transform: uppercase;
opacity: 1;
}

.contact-form-container .contact-detail {
  margin-bottom: 10px;
font: normal normal normal 16px/24px Novar;
letter-spacing: 0px;
line-height: 1.8;
color: #070707;

opacity: 1;
}

.contact-form-container .contact-form {
  display: flex;
  padding: 12vh 0vh;
}

.contact-form-container .input-column {
  display: flex;
  flex-direction: column;
  gap: 35px;
}

.contact-form-container .form-input,
.form-textarea {
  padding: 10px 32px;
  border-radius: 20px;
  border: none;
  color: #B1B1B1;
  outline: none;
  background-color: #E5E5E5;
}

.contact-form-container .textarea-portion {
  height: 100%;
  margin-left: 22px;
  width: 100%;
}

.contact-form-container .textarea-portion .form-textarea {
  height: 200px;
  resize: vertical;
  width: 100%;
}

.contact-form-container .form-input::placeholder,
.form-textarea::placeholder {
  color: #B1B1B1;
}

.contact-form-container .form-input:focus,
.form-textarea:focus {
  border-color: #A5CD37;
  outline: none;
}

.contact-form-container .textarea-portion .connect-button {
  background-color: #2A2522;
  border: none;
  margin-top: 12px;
}

@media only screen and (max-width: 992px) {
  .contact-form-container {
    padding: 10vh 3vh;
  }

  .contact-form-container .head-h2 {
    font-size: 36px;
    line-height: 1.2;
  }

  .contact-form-container .contact-form {
    flex-direction: column;
    padding: 8vh 0vh;
  }

  .contact-form-container .textarea-portion {
    margin-left: 0;
  }

  .contact-form-container .form-input,
  .form-textarea {
    padding: 8px 24px;
    font-size: 14px;
  }
}

/* Small devices (mobiles, small tablets) */
@media only screen and (max-width: 768px) {
  .contact-form-container {
    padding: 8vh 2vh;
    text-align: center;
  }

  .contact-form-container .head-h2 {
    font-size: 28px;
    line-height: 1.1;
    text-align: center;
  }

  .contact-form-container .contact-detail {
    font-size: 14px;
  }

  .contact-form-container .contact-form {
    padding: 6vh 0vh;
  }

  .contact-form-container .form-input,
  .form-textarea {
    padding: 8px 20px;
    font-size: 14px;
    border-radius: 10px;
  }

  .contact-form-container .textarea-portion {
    margin-left: 0;
    padding: 26px 0px;
  }
  .contact-inf {
    padding: 5vh 0vh;
  }
 
}

/* Extra small devices (small mobiles) */
@media only screen and (max-width: 480px) {
  .contact-form-container {
    padding: 6vh 2vh;
    
  }

  .contact-form-container .head-h2 {
    font-size: 24px;
    line-height: 1.1;
   
  }

  .contact-form-container .contact-detail {
    font-size: 12px;
    line-height: 2;
  }

  .contact-form-container .form-input
  {
    padding: 12px 16px;
    font-size: 12px;
    border-radius: 10px;
  }
  .contact-form-container .form-textarea {
    border-radius: 10px;
  }
}

