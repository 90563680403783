.Testimonials-container {
    background: url('../../../public/images/creatives/testimonial-bg.jpg') no-repeat center center/cover;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    position: relative;
    overflow: hidden;
}

.Testimonials-container .testbox {
    cursor: pointer;
    border-radius: 22px;
    background: transparent;
    height: 34vh;
    padding-top: 16px;
}

.Testimonials-container .testimonial-card .arrow-img {
    width: 45px;
}

.Testimonials-container .testimonial-card .testimonial-card-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.Testimonials-container .profile-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.Testimonials-container .user-img {
    border-radius: 50%;
    width: 85px;
    transform: translateY(-30px);
    filter: drop-shadow(0px 3px 9px rgba(0, 0, 0, 0.16));
}

.Testimonials-container .profile-info .review-text {
    color: #FFFFFF;
    text-align: center;
    font: italic normal medium 16px/35px Lato;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    line-height: 2;
    padding: 0px 20px;
}

.Testimonials-container .testimonial-card-line-seprator {
    border: 0.1px solid rgba(147, 147, 147, 0.3);
    width: 80%;
}

.Testimonials-container .profile-info .name {
    font-weight: 600;
    margin-top: 10px;
    text-align: center;
    font: normal normal normal 27px/29px Novar;
    letter-spacing: 0px;
    color: #FFFFFF;
    text-transform: uppercase;
    opacity: 1;
}

.Testimonials-container .btn-container .btn-arrow-icon {
    width: 14px;
    height: 14px;
}

.Testimonials-container .btn-container .btn-arrow-icon-left {
    transform: rotate(130deg);
    transition: transform .3s;
}

.Testimonials-container .btn-container .btn-arrow-icon-left-rotate {
    transition: transform .3s;
    transform: rotate(180deg);
}

.Testimonials-container .btn-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
}

.Testimonials-container .swiper-slide {
    transform: translate(7%, 0px);
    overflow-y: visible;
    display: block;
}

.Testimonials-container .swiper-slide-active {
    transform: translate(-7%, 0px);
    display: block;
}

.Testimonials-container .swiper-slide-next {
    transform: translate(0, 0) !important;
    display: grid;
    align-items: center;
    justify-content: center;
}

.Testimonials-container .swiper-slide-next .testimonial-card {
    width: 600px;
}

.Testimonials-container .swiper-pagination .swiper-pagination-bullet {
    background-color: #3A3A3A;
    opacity: 1;
}

.Testimonials-container .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #f58e09;
    opacity: 1;
}

.Testimonials-container .swiper-slider-inner {
    padding-top: 12px;
}

/* Media Queries for Mobile Responsiveness */
@media (max-width: 1450px) {
    .Testimonials-container .swiper-slide-active {
        transform: translate(0, 0) !important;
    }

    .Testimonials-container .swiper-slide-next {
        transform: translate(0, 0) !important;
    }

    .Testimonials-container .swiper-slide {
        transform: translate(0, 0) !important;
    }

    .Testimonials-container .swiper-slide-next .testimonial-card {
        width: auto;
    }

    .Testimonials-container .swiper-slide .testimonial-card {
        width: 80%;
        margin: auto;
    }

    .Testimonials-container .swiper {
        padding-left: 10px;
        padding-right: 10px;
    }
}

@media (min-width: 1850px) {
    .Testimonials-container .swiper-slide {
        overflow-y: visible;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .Testimonials-container .swiper-slide-active {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .Testimonials-container .swiper-slide-next {
        transform: translate(0, 0) !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .Testimonials-container .swiper-slide-next .testimonial-card,
    .Testimonials-container .swiper-slide-active .testimonial-card,
    .Testimonials-container .swiper-slide .testimonial-card {
        width: 600px;
        margin: 0 -50%;
    }
}

@media (max-width: 768px) {
    .Testimonials-container .swiper-slide .testimonial-card {
        width: 95%;
        height: 50vh;
        margin: auto;
    }

    .Testimonials-container .profile-info .review-text {
        padding: 0px;
    }
}

@media (max-width: 576px) {
    .Testimonials-container .swiper-slide .testimonial-card {
        width: 100%;
        margin: auto;
    }

    .Testimonials-container {
        padding: 10px;
        /* Adjust padding for very small screens */
    }

    .Testimonials-container .profile-info .review-text {
        font-size: 14px;
        /* Adjust font size for very small screens */
        padding: 0px;
    }

    .Testimonials-container .profile-info .name {
        font-size: 1.5rem;
        /* Adjust font size for very small screens */
    }

    .Testimonials-container .user-img {
        width: 70px;
        /* Adjust image size for very small screens */
    }

    .Testimonials-container .btn-container {
        flex-direction: column;
        gap: 10px;
    }
}