.admin-screen .h1_main{
    color: #2D2D2D;
}

.admin-screen {
    text-align: center;
    

}

.Admin-screen-main {
  

}

